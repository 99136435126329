export default {
  header: {
    controlPanel: 'Painel administrativo',
  },
  titles: {
    login: 'Login',
  },
  admin: {
    email: 'E-mail',
    password: 'Senha',
  },
  actions: {
    enter: 'Entrar',
  },
  validations: {
    admins: {
      email: {
        validEmail: 'E-mail inválido',
      },
      password: {
        string: 'Digite sua senha',
        minLength: 'Sua senha precisa ter ao menos 6 caracteres',
      },
    },
    categories: {
      name: {
        minLength: 'Digite uma categoria com pelo menos 2 caracteres',
      },
      slug: {
        minLength: 'Slug é obrigatório',
      },
      template_id: {
        number: 'Escolha um template',
        positive: 'Escolha um template',
      },
    },
    contents: {
      title: {
        minLength: 'Digite o título',
        maxLength: 'Máximo de 255 caracteres',
      },
      slug: {
        minLength: 'Slug é obrigatório',
        maxLength: 'Máximo de 255 caracteres',
      },
      category_id: {
        number: 'Escolha uma categoria',
      },
      template_id: {
        number: 'Escolha um template',
      },
      description: {
        minLength: 'Digite o texto',
      },
      date: {
        validDate: 'Data inválida',
      },
      hour: {
        validHour: 'Horário inválido',
      },
      youtube_id: {
        validYoutubeId: 'O ID do video do YouTube tem 11 caracteres',
      },
    },
    advisors: {
      code: {
        minLength: 'Digite o código',
        maxLength: 'Máximo de 255 caracteres',
      },
      first_name: {
        minLength: 'Digite o nome',
        maxLength: 'Máximo de 255 caracteres',
      },
      last_name: {
        minLength: 'Digite o sobrenome',
        maxLength: 'Máximo de 255 caracteres',
      },
      image: {
        minLength: 'Imagem é obrigatório'
      },
      sort: {
        number: 'Ordem inválida',
      },
    },
    tags: {
      name: {
        minLength: 'Digite o nome da tag',
        maxLength: 'Máximo de 255 caracteres',
      },
    },
  },
  date: {
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNames: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    amPm: ['am', 'pm'],
  },
}
