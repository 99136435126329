const initialState = {
  list: [],
  sending: false
}

const identifier = 'api_service'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_API_SERVICE_LIST':
      nextState = {
        ...state,
        list: action.data
      }
      break

    case 'REMOVE_API_SERVICE':
      nextState.list = nextState.list.filter(item => {
        return item.id !== action.data
      })
      break

    default:
      nextState = state
      break
  }

  return nextState
}
