import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'advisor'

const _setAdvisors = data => ({ type: 'SET_ADVISORS', data })
const _setAdvisor = data => ({ type: 'SET_ADVISOR', data })
const _updateAdvisor = data => ({ type: 'UPDATE_ADVISOR', data })
const _setAdvisorFilters = data => ({ type: 'SET_ADVISOR_FILTERS', data })
const _updateStatus = data => ({ type: 'UPDATE_ADVISOR_STATUS', data })
const _updateOnline = data => ({ type: 'UPDATE_ADVISOR_ONLINE', data })
const _updateHighlighted = data => ({ type: 'UPDATE_ADVISOR_HIGHLIGHTED', data })
const _updateSort = data => ({ type: 'UPDATE_ADVISOR_SORT', data })

export const loadAdvisors = (token, filters = {}) => {
  return async dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setAdvisorFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/advisors', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setAdvisors(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadAdvisor = (token, id) => {
  return async dispatch => {
    dispatch(_setAdvisor(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/advisors/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setAdvisor(result.data))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createAdvisor = (token, data) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/advisors', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'advisors' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateAdvisor = (token, data) => {
  return async dispatch => {
    updateAdvisorGeneric(dispatch, token, data.id, data, identifier, result => {
      dispatch(_updateAdvisor(result.data))
      dispatch(notificate(result.message, 'info'))
      dispatch(setRedirect({ screen: 'advisors' }))
    })
  }
}

export const toggleStatus = (token, id, status) => {
  return async dispatch => {
    const data = { status }
    updateAdvisorGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateStatus(result.data))
    })
  }
}

export const toggleOnline = (token, id, online) => {
  return async dispatch => {
    const data = { online }
    updateAdvisorGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateOnline(result.data))
    })
  }
}

export const toggleHighlighted = (token, id, highlighted) => {
  return async dispatch => {
    const data = { highlighted }
    updateAdvisorGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateHighlighted(result.data))
    })
  }
}

export const changeAdvisorSort = (token, id, sort, useIdentifier = false) => {
  return async dispatch => {
    const data = { sort }
    updateAdvisorGeneric(dispatch, token, id, data, useIdentifier ? identifier : null, result => {
      dispatch(_updateSort(result.data))
    })
  }
}

const updateAdvisorGeneric = (dispatch, token, id, data, identifier, onSuccess) => {
  dispatch(beforeSend(identifier))
  return api.put(`admin/advisors/${id}`, data, token)
    .then(result => {
      if (result.success) {
        onSuccess(result)
      } else {
        dispatch(notificate(result.message, 'error'))
      }
      dispatch(afterResponse(identifier))
    })
    .catch(error => {
      dispatch(notificate(error.message, 'error'))
      dispatch(afterResponse(identifier))
    })
}

export const destroyAdvisor = (token, id) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/advisors/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
