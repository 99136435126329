import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse
} from './'

const identifier = 'api_service'

const _setApiServiceList = data => ({ type: 'SET_API_SERVICE_LIST', data })
const _destroyApiService = data => ({ type: 'REMOVE_API_SERVICE', data })

export const loadApiServices = token => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/api-services', {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setApiServiceList(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyApiService = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/api-services/${id}`, {}, token)
      .then(result => {
        const type = result.success ? 'info' : 'error'
        if (result.success) {
          dispatch(_destroyApiService(id))
        }
        dispatch(notificate(result.message, type))
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadAuthURL = (token, service) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get(`admin/${service}/auth`, {}, token)
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const authCallback = (token, service, code) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get(`admin/${service}/callback`, { code }, token)
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
