import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createAdvisor } from '../../store/actions/advisor'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { AdvisorForm } from '../organisms'

class CreateAdvisor extends Component {
  render() {
    const { sending } = this.props.advisor
    return (
      <div>
        <BackToList to='advisors' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de Assessor</h1>
            <AdvisorForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='advisor' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.createAdvisor(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, advisor }) => ({
  admin,
  advisor,
})

const mapDispatchToProps = {
  createAdvisor,
  notificate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdvisor)
