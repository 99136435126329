import React, { Component } from 'react'
import _ from 'lodash'
import { Input, Switch } from '../atoms'

class Slug extends Component {
  static defaultProps = {
    value: '',
    mirror: '',
    onChange: () => { }
  }

  state = {
    equivalent: false
  }

  componentDidMount () {
    if (this.props.value === this._createSlug(this.props.mirror)) {
      this.setState({ equivalent: true })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.state.equivalent) {
      if (prevProps.mirror !== this.props.mirror) {
        this._sendMirrorToValue()
      }
    }
  }

  render() {
    const { equivalent } = this.state
    const safeProps = this._safeProps(this.props)
    return (
      <div className="slug-comp">
        <Input
          {...safeProps}
          disabled={equivalent}
          onChange={this._createSlugOnChange}
        />
        <div className="slug-comp__auto">
          <label className="slug-comp__auto__label">
            <span>Automático</span>
            <Switch
              checked={equivalent}
              onChange={this._onChangeEquivalent}
            />
          </label>
        </div>
      </div>
    )
  }

  _createSlug = value => {
    return _.kebabCase(value)
  }

  _createSlugOnChange = event => {
    let value = this._createSlug(event.target.value)
    const size = event.target.value.length
    const lastEnabled = [' ', '-']
    if (
      lastEnabled.includes(event.target.value[size - 1]) &&
      !this.state.equivalent
    ) {
      value += '-'
    }
    this.props.onChange({
      target: {
        value
      }
    })
  }

  _safeProps = props => {
    const newProps = { ...props }
    delete newProps.onChange
    delete newProps.mirror
    return newProps
  }

  _onChangeEquivalent = event => {
    const equivalent = event.target.checked
    this.setState({ equivalent }, () => {
      if (equivalent) {
        this._sendMirrorToValue()
      }
    })
  }

  _sendMirrorToValue = () => {
    this._createSlugOnChange({
      target: {
        value: this.props.mirror
      }
    })
  }
}

export default Slug
