const initialState = {
  data: null,
  list: [],
  paging: null,
  sending: false,
  filters: {}
}

const identifier = 'advisor'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_ADVISORS':
      nextState = {
        ...state,
        list: action.data.data,
        paging: action.data.paging
      }
      break

    case 'SET_ADVISOR':
      nextState.data = action.data
      break

    case 'SET_ADVISOR_FILTERS':
      nextState.filters = action.data
      break

    case 'UPDATE_ADVISOR_STATUS':
      nextState.list = nextState.list.map(item => {
        if (+item.id === +action.data.id) {
          item.status = action.data.status
        }
        return item
      })
      break

    case 'UPDATE_ADVISOR_HIGHLIGHTED':
      const { highlighted } = action.data
      nextState.list = nextState.list.map(item => {
        item.highlighted = +item.id === +action.data.id
          ? highlighted
          : false
        return item
      })
      break

    case 'UPDATE_ADVISOR_ONLINE':
      nextState.list = nextState.list.map(item => {
        if (+item.id === +action.data.id) {
          item.online = action.data.online
        }
        return item
      })
      break

    case 'UPDATE_ADVISOR_SORT':
      const sort = +action.data.sort
      const oldSort = nextState.list
        .filter(item => +item.id === +action.data.id)
        .map(item => +item.sort)
        .pop()

      nextState.list = nextState.list
        .map(item => {
          if (+item.id === +action.data.id) {
            item.sort = sort
          } else if (+item.sort === sort) {
            item.sort = oldSort
          }
          return item
        })
        .sort((a, b) => {
          if (a.sort < b.sort) return -1
          if (a.sort > b.sort) return 1
          return 0
        })
      break

    default:
      nextState = state
      break
  }

  return nextState
}
