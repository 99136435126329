import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createContent } from '../../store/actions/content'
import { loadCategories } from '../../store/actions/category'
import { loadTemplates } from '../../store/actions/template'
import { loadTags, createTag } from '../../store/actions/tag'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { ContentForm } from '../organisms'

class CreateContent extends Component {
  componentDidMount () {
    const { token } = this.props.admin.admin
    this.props.loadCategories(token, {
      options: true
    })
    this.props.loadTemplates(token, {
      options: true
    })
    this.props.loadTags(token, {
      options: true
    })
  }

  render() {
    const { sending } = this.props.content
    const { list: categories } = this.props.category
    const { list: templates } = this.props.template
    const { list: tags } = this.props.tag
    return (
      <div>
        <BackToList to='contents' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de Conteúdo</h1>
            <ContentForm
              sending={sending}
              categories={categories}
              templates={templates}
              tags={tags}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='content' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = async data => {
    const { token } = this.props.admin.admin
    let tags = []

    const newTags = data.tags.filter((item) => item.__isNew__)
    if (newTags.length > 0) {
      const newTagsResult = await this._onCreateTags(newTags)
      tags = tags.concat(
        newTagsResult.map((id) => ({ id }))
      )
    }

    const tagIds = tags.map((item) => +item.id)
    for (const item of data.tags) {
      if (!item.__isNew__ && !tagIds.includes(+item.value)) {
        tags.push({ id: +item.value })
      }
    }

    this.props.createContent(token, {
      ...data,
      tags
    })
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }

  _onCreateTags = async (newTags) => {
    const { token } = this.props.admin.admin
    const ids = []
    for (const tag of newTags) {
      const data = {
        name: tag.value,
        slug: tag.value,
        status: 'enabled'
      }
      const result = await this.props.createTag(token, data, false)
      if (result.success) {
        ids.push(+result.data.id)
      }
    }
    return ids
  }
}

const mapStateToProps = ({ admin, content, category, template, tag }) => ({
  admin,
  content,
  category,
  template,
  tag
})

const mapDispatchToProps = {
  createContent,
  notificate,
  loadCategories,
  loadTemplates,
  loadTags,
  createTag
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContent)
