import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Switch } from '../atoms'
import { ImagePreview, SortChanger } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadAdvisors,
  toggleStatus,
  toggleOnline,
  toggleHighlighted,
  destroyAdvisor,
  changeAdvisorSort,
} from '../../store/actions/advisor'
import { links, navigate } from '../../utils'

class Advisors extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: '',
      status: '',
      highlighted: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, sending } = this.props.advisor
    const { currentId } = this.state
    const columns = this._getColumns()
    const filterOptions = this._getFilterOptions()

    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link
            button
            disabled={sending}
            size='small'
            to={links.get('createAdvisor')}
          >
            + Criar Assessor
          </Link>
          <Link
            button
            disabled={sending}
            size='small'
            onClick={this._reorder}
          >
            Reordenar alfabeticamente
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.advisor.filters}
          filters={['search', 'status', 'highlighted']}
          filterOptions={filterOptions}
        />
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadAdvisors(token, filters)
  }

  _getColumns = () => {
    return [
      {
        name: 'Ordem',
        field: 'sort',
        filter: (value, data) => {
          const appliedFilters = this._isAppliedFilters()
          if (appliedFilters) return false
          const { sending, list } = this.props.advisor
          const max = list.length - 1
          return (
            <SortChanger
              hasUp={data.sort > 0}
              hasDown={data.sort < max}
              sending={sending}
              max={max}
              value={+value}
              onChange={value => this._onChangeSort(data.id, value)}
            />
          )
        }
      },
      {
        name: 'Imagem',
        field: 'image_thumb_url',
        filter: (value, data) => {
          if (!value) return false
          return (
            <ImagePreview
              thumbnail={value}
              image={data.image_large_url}
              width="50"
              className="thumbnail"
              alt={data.title}
            />
          )
        }
      },
      {
        name: 'Nome',
        field: 'first_name',
        filter: (value, data) =>
          [data.first_name, data.last_name].join(' ')
      },
      {
        name: 'Código',
        field: 'code',
      },
      {
        name: 'WhatsApp',
        field: 'whatsapp',
      },
      {
        name: 'Online',
        field: 'onlineFormated',
      },
      {
        name: 'Destaque',
        field: 'highlightedFormated',
      },
      {
        name: 'Ativo',
        field: 'statusFormated',
      }
    ]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editAdvisor',
      params: { id: item.id },
      props: this.props
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyAdvisor(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    const switchValue = (checked, onChange) =>
      <Switch
        checked={checked}
        onChange={onChange}
      />
    return list.map(item => {
      return {
        ...item,
        onlineFormated: switchValue(item.online, event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const online = event.target.checked
          this.props.toggleOnline(token, id, online)
        }),
        highlightedFormated: switchValue(item.highlighted, event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const highlighted = event.target.checked
          this.props.toggleHighlighted(token, id, highlighted)
        }),
        statusFormated: switchValue(item.status === 'enabled', event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const status = event.target.checked
            ? 'enabled'
            : 'disabled'
          this.props.toggleStatus(token, id, status)
        }),
      }
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.advisor.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.advisor.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _sortColumn = column => {
    return direction => {
      const signal = direction === 'desc' ? '-' : ''
      const sort = `${signal}${column}`
      const filters = {
        ...this.state.filters,
        sort
      }
      this.setState({ filters }, this._reloadData)
    }
  }

  _currentSortColumn = column => {
    const { sort } = this.state.filters
    let direction = 'asc'
    if (sort.includes(column)) {
      direction = sort[0] === '-' ? 'desc' : 'asc'
    }
    return direction
  }

  _getFilterOptions = () => {
    const filterOptions = {
      status: {
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Habilitados',
            value: 'enabled',
          },
          {
            label: 'Desabilitados',
            value: 'disabled',
          },
        ],
      },
      highlighted: {
        label: 'Destaque',
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Sim',
            value: 'true',
          },
          {
            label: 'Não',
            value: 'false',
          },
        ],
      }
    }
    return filterOptions
  }

  _onChangeSort = (id, value, useIdentifier = false) => {
    const { token } = this.props.admin.admin
    this.props.changeAdvisorSort(token, id, value, useIdentifier)
  }

  _isAppliedFilters = () => {
    const { search, status, highlighted } = this.state.filters
    return search !== '' || status !== '' || highlighted !== ''
  }

  _reorder = async () => {
    const { list, sending } = this.props.advisor
    if (!sending) {
      const promises = [...list]
        .sort((a, b) => a.first_name < b.first_name ? -1 : (a.first_name > b.first_name ? 1 : 0))
        .map((item, index) => {
          if (+item.sort !== +index) {
            return this._onChangeSort(item.id, index, true)
          }
          return null
        })
      await Promise.all(promises)
    }
  }
}

const mapStateToProps = ({ admin, advisor }) => ({
  admin,
  advisor,
})

const mapDispatchToProps = {
  loadAdvisors,
  destroyAdvisor,
  toggleStatus,
  toggleOnline,
  toggleHighlighted,
  changeAdvisorSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(Advisors)
