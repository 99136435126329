import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse
} from './'

const identifier = 'contact'

const _setContacts = data => ({ type: 'SET_CONTACTS', data })
const _setContact = data => ({ type: 'SET_CONTACT', data })
const _setContactFilters = data => ({ type: 'SET_CONTACT_FILTERS', data })

export const loadContacts = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setContactFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/contacts', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setContacts(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadContact = (token, id) => {
  return dispatch => {
    dispatch(_setContact(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/contacts/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setContact(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyContact = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/contacts/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const exportContacts = (token, params) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/contacts/export', params, token)
      .then(result => {
        if (!result.success) {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
