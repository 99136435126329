import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'category'

const _setCategories = data => ({ type: 'SET_CATEGORIES', data })
const _setCategory = data => ({ type: 'SET_CATEGORY', data })
const _updateCategory = data => ({ type: 'UPDATE_CATEGORY', data })
const _setCategoryFilters = data => ({ type: 'SET_CATEGORY_FILTERS', data })

export const loadCategories = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setCategoryFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/categories', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setCategories(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadCategory = (token, id) => {
  return dispatch => {
    dispatch(_setCategory(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/categories/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setCategory(result.data))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createCategory = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/categories', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'categories' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateCategory = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/categories/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateCategory(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'categories' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyCategory = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/categories/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const captureCategory = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get(`admin/categories/${id}/capture`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
