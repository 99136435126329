import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { adminValidator } from '../../validators'
import { form } from '../../utils'

export default class AdminForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      image: '',
      email: '',
      status: true
    },
    errors: {}
  }

  fields = [{
    label: 'Nome',
    field: 'name',
    type: 'text'
  }, {
    label: 'E-mail',
    field: 'email',
    type: 'text'
  }, {
    label: 'Senha',
    field: 'password',
    type: 'password'
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      const checked = model.status
      return { checked }
    }
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
        status: data.status === 'enabled'
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      const value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props({ props: this.props, model })
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          value={value}
          onChange={this._onChange(item.field)}
          errors={form.getErrors(item.field, errors)}
        />
      )
    })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const { model } = this.state
    const data = {
      ...model,
      status: model.status ? 'enabled' : 'disabled'
    }
    const method = !this.props.data ? 'create' : 'edit'
    adminValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const model = {
        ...this.state.model,
        [field]: value
      }
      this.setState({ model })
    }
  }
}
