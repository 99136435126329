import { combineReducers } from 'redux'
import admin from './admin'
import notification from './notification'
import base from './base'
import dashboard from './dashboard'
import setting from './setting'
import category from './category'
import template from './template'
import content from './content'
import advisor from './advisor'
import contact from './contact'
import joinUs from './join_us'
import apiService from './api_service'
import tag from './tag'

export default combineReducers({
  admin,
  notification,
  base,
  dashboard,
  setting,
  category,
  template,
  content,
  advisor,
  contact,
  joinUs,
  apiService,
  tag,
})
