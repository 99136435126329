import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Switch } from '../atoms'
import { ImagePreview, YoutubePreview } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadContents,
  toggleStatus,
  toggleHighlighted,
  destroyContent
} from '../../store/actions/content'
import { loadCategories } from '../../store/actions/category'
import { loadTags } from '../../store/actions/tag'
import { date, links, navigate } from '../../utils'

class Contents extends Component {
  state = {
    filters: {
      page: 1,
      limit: 50,
      search: '',
      status: '',
      tag_id: '',
      category_id: '',
      highlighted: ''
    },
    currentId: null
  }

  componentDidMount() {
    const { token } = this.props.admin.admin
    this.props.loadCategories(token, {
      options: true,
    })
    this.props.loadTags(token, {
      options: true,
    })
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.content
    const { currentId } = this.state
    const columns = this._getColumns()
    const filterOptions = this._getFilterOptions()

    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link
            button
            disabled={sending}
            size='small'
            to={links.get('createContent')}
          >
            + Criar Conteúdo
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.content.filters}
          filters={['search', 'category_id', 'tag_id', 'status', 'highlighted']}
          filterOptions={filterOptions}
        />
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadContents(token, filters)
  }

  _getColumns = () => {
    return [
      {
        name: 'Imagem',
        field: 'image_thumb_url',
        filter: (value, data) => {
          if (!value) return false
          return (
            <ImagePreview
              thumbnail={value}
              image={data.image_large_url}
              width="50"
              className="thumbnail"
              alt={data.title}
            />
          )
        }
      },
      {
        name: 'Título',
        field: 'title'
      },
      {
        name: 'Categoria',
        field: 'category',
        filter: value => {
          if (!value) return false
          return value.name
        }
      },
      {
        name: 'Template',
        field: 'template',
        filter: value => {
          if (!value) return false
          return value.name
        }
      },
      {
        name: 'Video',
        field: 'youtube_id',
        filter: (value, data) => {
          if (!value) return false
          return (
            <YoutubePreview
              className="thumbnail"
              thumbnail={data.youtube_thumb}
              id={data.youtube_id}
              width="50"
            />
          )
        }
      },
      {
        name: 'Data',
        field: 'date',
        filter: value => {
          return date.formatDatetime(value)
        }
      },
      {
        name: 'Destaque home',
        field: 'highlightedFormated'
      },
      {
        name: 'Ativo',
        field: 'statusFormated'
      }
    ]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editContent',
      params: { id: item.id },
      props: this.props
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyContent(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    const switchValue = (checked, onChange) =>
      <Switch
        checked={checked}
        onChange={onChange}
      />
    return list.map(item => {
      return {
        ...item,
        highlightedFormated: switchValue(item.highlighted, event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const highlighted = event.target.checked
          this.props.toggleHighlighted(token, id, highlighted)
        }),
        statusFormated: switchValue(item.status === 'enabled', event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const status = event.target.checked
            ? 'enabled'
            : 'disabled'
          this.props.toggleStatus(token, id, status)
        }),
      }
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.content.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.content.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _sortColumn = column => {
    return direction => {
      const signal = direction === 'desc' ? '-' : ''
      const sort = `${signal}${column}`
      const filters = {
        ...this.state.filters,
        sort
      }
      this.setState({ filters }, this._reloadData)
    }
  }

  _currentSortColumn = column => {
    const { sort } = this.state.filters
    let direction = 'asc'
    if (sort.includes(column)) {
      direction = sort[0] === '-' ? 'desc' : 'asc'
    }
    return direction
  }

  _getFilterOptions = () => {
    const { list: categoryList } = this.props.category
    const { list: tagList } = this.props.tag
    const filterOptions = {
      category_id: {
        options: [
          {
            label: 'Todas',
            value: '',
          },
          {
            label: 'Sem categoria',
            value: 'empty',
          },
        ].concat(
          categoryList.map(item => ({
            label: item.name,
            value: item.id,
          }))
        ),
      },
      tag_id: {
        options: [
          {
            label: 'Todas',
            value: '',
          },
        ].concat(
          tagList.map(item => ({
            label: item.name,
            value: item.id,
          }))
        ),
      },
      status: {
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Habilitados',
            value: 'enabled',
          },
          {
            label: 'Desabilitados',
            value: 'disabled',
          },
        ],
      },
      highlighted: {
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Sim',
            value: 'true',
          },
          {
            label: 'Não',
            value: 'false',
          },
        ],
      }
    }
    return filterOptions
  }
}

const mapStateToProps = ({ admin, content, category, tag }) => ({
  admin,
  content,
  category,
  tag,
})

const mapDispatchToProps = {
  loadContents,
  destroyContent,
  toggleStatus,
  toggleHighlighted,
  loadCategories,
  loadTags,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
