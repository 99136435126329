import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { categoryValidator } from '../../validators'
import { form } from '../../utils'

export default class CategoryForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(PropTypes.object).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    model: {
      parent_id: '',
      name: '',
      slug: '',
      playlist_id: '',
      template_id: '',
      status: true
    },
    errors: {}
  }

  fields = [{
    label: 'Categoria pai',
    field: 'parent_id',
    type: 'select'
  }, {
    label: 'Nome',
    field: 'name',
    type: 'text'
  }, {
    label: 'Slug',
    field: 'slug',
    type: 'slug',
    props({ model }) {
      return {
        mirror: model.name
      }
    }
  }, {
    label: 'ID da playlist no YouTube',
    field: 'playlist_id',
    type: 'text'
  }, {
    label: 'Template do conteúdo da categoria',
    field: 'template_id',
    type: 'radioButtons'
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      return {
        checked: model.status
      }
    }
  }]

  constructor(props) {
    super(props)
    const { data } = props
    if (data) {
      this.state.model = {
        ...this.state.model,
        ...data,
        status: data.status === 'enabled'
      }
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      let value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props.apply(this, [{ props: this.props, model }])
      }
      if (item.field === 'template_id') {
        otherProps.options = this.props.templates.map(item => ({
          value: item.id,
          label: item.name,
          icon: `${item.code}-template`,
        }))
      } else if (item.field === 'parent_id') {
        otherProps.options = [{
          value: '',
          label: 'Nenhuma'
        }].concat(
          this.props.categories.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          mask={item.mask}
          value={value}
          onChange={this._onChange(item.field)}
          errors={form.getErrors(item.field, errors)}
        >{otherProps.children}</FormControl>
      )
    })
  }

  _handleSubmit = async event => {
    event.preventDefault()
    try {
      const { model } = this.state
      const data = {
        ...model,
        template_id: model.template_id
          ? +model.template_id
          : '',
        status: model.status ? 'enabled' : 'disabled'
      }
      if (!data.parent_id) {
        data.parent_id = null
      }
      const method = !this.props.data ? 'create' : 'edit'
      categoryValidator[method](data).then(result => {
        this.setState({ errors: {} })
        if (result.success) {
          this.props.onSubmit(data)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    } catch (error) {
      this.props.notificate(error.message)
    }
  }

  _onChange = field => {
    const blockFields = []
    return value => {
      if (!blockFields.includes(field)) {
        const model = {
          ...this.state.model,
          [field]: value
        }
        this.setState({ model })
      }
    }
  }
}
