import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Switch } from '../atoms'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadTags,
  toggleStatus,
  destroyTag
} from '../../store/actions/tag'
import { links, navigate } from '../../utils'

class Tags extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: '',
      status: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.tag
    const { currentId } = this.state
    const columns = this._getColumns()
    const filterOptions = this._getFilterOptions()

    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link
            button
            disabled={sending}
            size='small'
            to={links.get('createTag')}
          >
            + Criar Tag
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.tag.filters}
          filters={['search', 'status']}
          filterOptions={filterOptions}
        />
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadTags(token, filters)
  }

  _getColumns = () => {
    return [
      {
        name: 'Tag',
        field: 'name'
      },
      {
        name: 'Slug',
        field: 'slug'
      },
      {
        name: 'Ativo',
        field: 'statusFormated'
      }
    ]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editTag',
      params: { id: item.id },
      props: this.props
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyTag(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    const switchValue = (checked, onChange) =>
      <Switch
        checked={checked}
        onChange={onChange}
      />
    return list.map(item => {
      return {
        ...item,
        statusFormated: switchValue(item.status === 'enabled', event => {
          const { token } = this.props.admin.admin
          const { id } = item
          const status = event.target.checked
            ? 'enabled'
            : 'disabled'
          this.props.toggleStatus(token, id, status)
        }),
      }
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.tag.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.tag.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _sortColumn = column => {
    return direction => {
      const signal = direction === 'desc' ? '-' : ''
      const sort = `${signal}${column}`
      const filters = {
        ...this.state.filters,
        sort
      }
      this.setState({ filters }, this._reloadData)
    }
  }

  _currentSortColumn = column => {
    const { sort } = this.state.filters
    let direction = 'asc'
    if (sort.includes(column)) {
      direction = sort[0] === '-' ? 'desc' : 'asc'
    }
    return direction
  }

  _getFilterOptions = () => {
    const filterOptions = {
      status: {
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Habilitados',
            value: 'enabled',
          },
          {
            label: 'Desabilitados',
            value: 'disabled',
          },
        ],
      },
    }
    return filterOptions
  }
}

const mapStateToProps = ({ admin, tag }) => ({
  admin,
  tag
})

const mapDispatchToProps = {
  loadTags,
  destroyTag,
  toggleStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
