import React, { Component } from 'react'
import fecha from 'fecha'
import { connect } from 'react-redux'
import { Link } from '../atoms'
import { Spinner } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadContacts,
  destroyContact,
  exportContacts
} from '../../store/actions/contact'
import { date, downloadFile } from '../../utils'

class Contacts extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.contact
    const { currentId } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link
            button
            disabled={sending}
            size='small'
            onClick={this._exportContacts}
          >
            + Exportar Contatos
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.contact.filters}
          filters={['search']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.contact.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.contact.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadContacts(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Nome',
      field: 'name'
    }, {
      name: 'E-mail',
      field: 'email'
    }, {
      name: 'Telefone',
      field: 'phone'
    }, {
      name: 'Mensagem',
      field: 'message',
      filter: value => {
        if (value && value.length > 30) {
          return value.substring(0, 30) + '...'
        }
        return value
      }
    }, {
      name: 'Data',
      field: 'created_at',
      filter: value => date.formatDatetime(value)
    }]
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyContact(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    return list.map(item => {
      return {
        ...item,
        statusFormated: (
          item.status === 'enabled'
            ? 'Sim'
            : 'Não'
        )
      }
    })
  }

  _exportContacts = () => {
    const { token } = this.props.admin.admin
    const filtersExport = { ...this.state.filters }
    delete filtersExport.page
    delete filtersExport.limit
    this.props.exportContacts(token, filtersExport).then(data => {
      const name = `contatos-${fecha.format(new Date(), 'DD-MM-YYYY')}.csv`
      downloadFile({
        type: 'text/csv',
        name,
        data
      })
    })
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    contact: state.contact
  }
}

const mapDispatchToProps = {
  loadContacts,
  destroyContact,
  exportContacts
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
