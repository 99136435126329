const youtubeUtils = {
  getIdByLink (link) {
    const regex = /youtu(?:.*\/v\/|.*v=|\.be\/)([A-Za-z0-9_-]{11})/
    const matches = link.match(regex)
    if (matches) {
      return matches[1]
    }
  },
  getImage (id) {
    return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
  },
}

export default youtubeUtils
