import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, YoutubePreview } from '../molecules'
import { Button } from '../atoms'
import { contentValidator } from '../../validators'
import { date, form, youtubeUtils } from '../../utils'

export default class ContentForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    templates: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    model: {
      category_id: '',
      template_id: '',
      title: '',
      slug: '',
      subtitle: '',
      description: '',
      source_name: '',
      source_url: '',
      youtube_id: '',
      youtube_thumb: '',
      date: new Date(),
      hour: '00:00',
      image: '',
      highlighted: false,
      status: true,
      tags: [],
      titleAndSubtitle: '',
    },
    errors: {}
  }

  fields = [
    {
      label: 'Imagem',
      field: 'image',
      type: 'image',
      props({ model }) {
        return {
          preview: model && model.image_large_url
            ? model.image_large_url
            : null
        }
      }
    },
    {
      label: 'Data',
      field: 'date',
      type: 'calendar',
    },
    {
      label: 'Hora',
      field: 'hour',
      type: 'mask',
      mask: 'hour'
    },
    {
      label: 'Categoria',
      field: 'category_id',
      type: 'select'
    },
    {
      label: 'Template',
      field: 'template_id',
      type: 'radioButtons'
    },
    {
      label: 'Título',
      field: 'title',
      type: 'text'
    },
    {
      label: 'Sub-título',
      field: 'subtitle',
      type: 'text'
    },
    {
      label: 'Slug',
      field: 'slug',
      type: 'slug',
      props({ model }) {
        return {
          mirror: model.titleAndSubtitle
        }
      }
    },
    {
      label: 'Texto',
      field: 'description',
      type: 'texteditor'
    },
    {
      label: 'Nome da Fonte',
      field: 'source_name',
      type: 'text'
    },
    {
      label: 'URL da Fonte',
      field: 'source_url',
      type: 'text'
    },
    {
      label: 'Código do YouTube',
      field: 'youtube_id',
      type: 'text',
      helpText: 'Cole a URL do video para extrair o ID',
      props ({ model }) {
        return {
          children: model.youtube_thumb
            ?
              <div style={{ marginTop: 10 }}>
                <YoutubePreview
                  thumbnail={model.youtube_thumb}
                  id={model.youtube_id}
                  width="100"
                  className="thumbnail"
                  alt={model.youtube_id}
                />
              </div>
            : null
        }
      }
    },
    {
      label: 'Tags',
      field: 'tags',
      type: 'tag',
    },
    {
      label: 'Destaque da Home',
      field: 'highlighted',
      type: 'switch',
      props({ model }) {
        return {
          checked: model.highlighted
        }
      }
    },
    {
      label: 'Ativo',
      field: 'status',
      type: 'switch',
      props({ model }) {
        return {
          checked: model.status
        }
      }
    },
  ]

  constructor(props) {
    super(props)
    const { data } = props
    if (data) {
      const contentDate = date.parseDatetime(data.date)
      const titleAndSubtitle = `${data.title} ${data.subtitle}`
      this.state.model = {
        ...this.state.model,
        ...data,
        titleAndSubtitle,
        date: contentDate,
        hour: date.formatDatetime(contentDate, null, 'HH:mm'),
        status: data.status === 'enabled',
        tags: data.tags.map((tag) => ({
          value: +tag.id,
          label: tag.name
        }))
      }
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={(e) => e.preventDefault()}>
        {this._getFields()}
        <Button
          onClick={this._handleSubmit}
          type='button'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      let value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props.apply(this, [{ props: this.props, model }])
      }
      if (item.field === 'category_id') {
        otherProps.options = [{
          label: 'Selecione',
          value: ''
        }].concat(
          this.props.categories.map(item => {
            let label = item.name
            if (item.parent) {
              label = `${item.parent.name} > ${label}`
            }
            return {
              value: item.id,
              label,
            }
          })
        )
      }
      if (item.field === 'template_id') {
        otherProps.options = this.props.templates.map(item => ({
          value: item.id,
          label: item.name,
          icon: `${item.code}-template`,
        }))
      }
      if (item.field === 'tags') {
        otherProps.options = this.props.tags.map(item => ({
          value: item.id,
          label: item.name
        }))
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          mask={item.mask}
          multiple={item.multiple}
          helpText={item.helpText}
          value={value}
          onChange={this._onChange(item.field)}
          errors={form.getErrors(item.field, errors)}
        >{otherProps.children}</FormControl>
      )
    })
  }

  _handleSubmit = async event => {
    event.preventDefault()
    try {
      const { model } = this.state
      const data = {
        ...model,
        category_id: model.category_id
          ? +model.category_id
          : '',
        template_id: model.template_id
          ? +model.template_id
          : '',
        date: model.date
          ? date.formatDate(model.date, null, 'YYYY-MM-DD') + ' ' + model.hour
          : '',
        // tags,
        status: model.status ? 'enabled' : 'disabled'
      }
      const method = !this.props.data ? 'create' : 'edit'
      contentValidator[method](data).then(result => {
        this.setState({ errors: {} })
        if (result.success) {
          this.props.onSubmit(data)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    } catch (error) {
      this.props.notificate(error.message)
    }
  }

  _onChange = field => {
    const blockFields = []
    return value => {
      if (!blockFields.includes(field)) {
        const model = {
          ...this.state.model,
          [field]: value
        }
        if (field === 'youtube_id' && value.startsWith('http')) {
          const youtubeId = youtubeUtils.getIdByLink(value)
          model.youtube_id = youtubeId ? youtubeId : ''
          if (youtubeId) {
            model.youtube_thumb = youtubeUtils.getImage(youtubeId)
          }
        }
        if (field === 'category_id') {
          const category = this.props.categories.find((item) => {
            return +item.id === +value
          })
          if (category && category.template) {
            model.template_id = +category.template.id
          }
        }
        if (field === 'title' || field === 'subtitle') {
          model.titleAndSubtitle = `${model.title} ${model.subtitle}`
        }
        this.setState({ model })
      }
    }
  }
}
