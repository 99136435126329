export default function ({ name, type, data, isBase64 }) {
  let blob = new Blob([data])

  if (isBase64) {
    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    blob = new Blob([byteArray], { type })
  }

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, name)
  } else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, { type })
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
