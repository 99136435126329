import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadAdvisor, updateAdvisor } from '../../store/actions/advisor'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { AdvisorForm } from '../organisms'

class EditAdvisor extends Component {
  constructor(props) {
    super(props)
    const { token } = props.admin.admin
    const { id } = props.match.params
    props.loadAdvisor(token, id)
  }

  render() {
    const { data, sending } = this.props.advisor
    return (
      <div>
        <BackToList to='advisors' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar Assessor</h1>
            {data &&
              <AdvisorForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='advisor' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.updateAdvisor(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, advisor }) => ({
  admin,
  advisor,
})

const mapDispatchToProps = {
  loadAdvisor,
  updateAdvisor,
  notificate,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAdvisor)
