import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { links } from '../../utils'

export default class HeaderMenu extends Component {
  state = {
    opened: false
  }

  render () {
    const classes = this._getClasses()
    return (
      <div className={classes}>
        <div
          className="header-menu__open-nav"
          onClick={this._toggleNav}
        >
          <span className="header-menu__open-nav__line"></span>
          <span className="header-menu__open-nav__line"></span>
          <span className="header-menu__open-nav__line"></span>
        </div>
        <div className="header-menu__nav">
          <ul className="header-menu__nav__items">
            {this._getItems()}
          </ul>
        </div>
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['header-menu']
    if (this.state.opened) {
      classes.push('header-menu--is-opened')
    }
    return classes.join(' ')
  }

  _toggleNav = () => {
    this.setState({ opened: !this.state.opened })
  }

  _getItems = () => {
    const items = [
      {
        label: 'Dashboard',
        screen: 'dashboard'
      },
      {
        label: 'Categorias',
        screen: 'categories'
      },
      {
        label: 'Tags',
        screen: 'tags'
      },
      {
        label: 'Conteúdo',
        screen: 'contents'
      },
      {
        label: 'Assessores',
        screen: 'advisors'
      },
      {
        label: 'Nucleopar',
        screen: 'joinUs'
      },
      {
        label: 'Contato',
        screen: 'contacts'
      },
      {
        label: 'API Services',
        screen: 'apiServices'
      },
      {
        label: 'Administradores',
        screen: 'admins'
      },
      {
        label: 'Configurações',
        screen: 'settings'
      }
    ]
    let currentScreen
    if (this.props.location) {
      currentScreen = links.getReverse(this.props.location.pathname)
    }
    return items.map((item, index) => {
      let classes = ['header-menu__nav__item']
      if (currentScreen === item.screen) {
        classes.push('header-menu__nav__item--active')
      }
      return (
        <li
          key={index}
          className={classes.join(' ')}
        >
          <Link
            to={links.get(item.screen)}
            className="header-menu__nav__item__link"
            onClick={this._toggleNav}
          >
            {item.label}
          </Link>
        </li>
      )
    })
  }
}
