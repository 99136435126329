import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createCategory, loadCategories } from '../../store/actions/category'
import { loadTemplates } from '../../store/actions/template'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { CategoryForm } from '../organisms'

class CreateCategory extends Component {
  componentDidMount () {
    const { token } = this.props.admin.admin
    this.props.loadTemplates(token, {
      options: true
    })
    this.props.loadCategories(token, {
      options: true,
      first_level: true,
    })
  }

  render() {
    const { sending } = this.props.category
    const { list: templates } = this.props.template
    const { list: categories } = this.props.category
    return (
      <div>
        <BackToList to='categories' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de Categoria</h1>
            <CategoryForm
              sending={sending}
              categories={categories}
              templates={templates}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='category' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.createCategory(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, category, template }) => ({
  admin,
  category,
  template,
})

const mapDispatchToProps = {
  createCategory,
  notificate,
  loadTemplates,
  loadCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory)
