import * as pages from '../components/pages'

export default {
  routes: [
    {
      name: 'dashboard',
      path: '/',
      component: pages.Dashboard,
      requiresAuth: true
    },
    {
      name: 'admins',
      path: '/usuarios',
      component: pages.Admins,
      requiresAuth: true
    },
    {
      name: 'createAdmin',
      path: '/usuarios/criar',
      component: pages.CreateAdmin,
      requiresAuth: true
    },
    {
      name: 'editAdmin',
      path: '/usuarios/editar/:id',
      component: pages.EditAdmin,
      requiresAuth: true
    },
    {
      name: 'settings',
      path: '/configuracoes',
      component: pages.Settings,
      requiresAuth: true
    },
    {
      name: 'login',
      path: '/login',
      component: pages.Login
    },
    {
      name: 'logout',
      path: '/logout',
      component: pages.Logout,
      requiresAuth: true
    },
    {
      name: 'categories',
      path: '/categories',
      component: pages.Categories,
      requiresAuth: true
    },
    {
      name: 'createCategory',
      path: '/categories/adicionar',
      component: pages.CreateCategory,
      requiresAuth: true
    },
    {
      name: 'editCategory',
      path: '/categories/editar/:id',
      component: pages.EditCategory,
      requiresAuth: true
    },
    {
      name: 'contents',
      path: '/conteudo',
      component: pages.Contents,
      requiresAuth: true
    },
    {
      name: 'createContent',
      path: '/conteudo/adicionar',
      component: pages.CreateContent,
      requiresAuth: true
    },
    {
      name: 'editContent',
      path: '/conteudo/editar/:id',
      component: pages.EditContent,
      requiresAuth: true
    },
    {
      name: 'advisors',
      path: '/assessores',
      component: pages.Advisors,
      requiresAuth: true
    },
    {
      name: 'createAdvisor',
      path: '/assessores/adicionar',
      component: pages.CreateAdvisor,
      requiresAuth: true
    },
    {
      name: 'editAdvisor',
      path: '/assessores/editar/:id',
      component: pages.EditAdvisor,
      requiresAuth: true
    },
    {
      name: 'contacts',
      path: '/contatos',
      component: pages.Contacts,
      requiresAuth: true
    },
    {
      name: 'joinUs',
      path: '/nucleopar',
      component: pages.JoinUs,
      requiresAuth: true
    },
    {
      name: 'apiServices',
      path: '/api-services',
      component: pages.ApiServices,
      requiresAuth: true
    },
    {
      name: 'apiServiceCallback',
      path: '/api-services/:service/callback',
      component: pages.ApiServices,
      requiresAuth: true
    },
    {
      name: 'tags',
      path: '/tags',
      component: pages.Tags,
      requiresAuth: true
    },
    {
      name: 'createTag',
      path: '/tags/adicionar',
      component: pages.CreateTag,
      requiresAuth: true
    },
    {
      name: 'editTag',
      path: '/tags/editar/:id',
      component: pages.EditTag,
      requiresAuth: true
    },
  ]
}
