import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadTag, updateTag } from '../../store/actions/tag'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { TagForm } from '../organisms'

class EditTag extends Component {
  constructor(props) {
    super(props)
    const { token } = props.admin.admin
    const { id } = props.match.params
    props.loadTag(token, id)
    props.loadCategories(token, {
      options: true
    })
    props.loadTemplates(token, {
      options: true
    })
  }

  render() {
    const { data, sending } = this.props.tag
    return (
      <div>
        <BackToList to='tags' />
        <div className="form-and-tips">
          <div classTag="form-and-tips__form">
            <h1 className="page-title">Editar Tag</h1>
            {data &&
              <TagForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='tag' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubTag = data => {
    const { token } = this.props.admin.admin
    this.props.updateTag(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, tag }) => ({
  admin,
  tag
})

const mapDispatchToProps = {
  loadTag,
  updateTag,
  notificate
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)
