import React, { Component } from 'react'
import classNames from 'classnames'
import Calendar from 'react-calendar'
import Dropzone from 'react-dropzone'
import fecha from 'fecha'
import RichTextEditor from 'react-rte'
import CreatableSelect from 'react-select/creatable'
import {
  Input,
  Checkbox,
  Radio,
  Select,
  Textarea,
  Icon,
  Switch
} from '../atoms'
import Slug from './Slug'

class FormControl extends Component {
  static defaultProps = {
    value: '',
    errors: [],
    type: 'text',
    name: null,
    label: null,
    onChange: () => { },
    onSelectFile: () => { },
    preview: null,
    icon: null,
    mirror: null,
    helpText: null,
  }

  state = {
    showCalendar: false,
    filePreview: null,
    previewImageName: 'Selecione uma imagem',
    previewFileName: 'Selecione um arquivo',
    textEditorValue: RichTextEditor.createEmptyValue(),
    textEditorValueSetted: false
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.type === 'texteditor' &&
      this.props.value !== '' &&
      !this.state.textEditorValueSetted
    ) {
      this.setState({
        textEditorValueSetted: true,
        textEditorValue: RichTextEditor.createValueFromString(
          this.props.value,
          'html'
        )
      })
    }
  }

  render() {
    return (
      <div className={this._getClasses()}>
        {this._getLabel()}
        {this._getInput()}
        {this._getHelpText()}
        {this._getErrors()}
        {this.props.children}
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['form__control']
    if (this.props.errors.length > 0) {
      classes.push('form__control--error')
    }
    return classes.join(' ')
  }

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  }

  _onChange = event => {
    if (event.target.type === 'checkbox') {
      let value = event.target.checked
      if (
        (
          event.target.value &&
          event.target.value !== 'true' &&
          event.target.value !== 'false' &&
          event.target.value !== '0' &&
          event.target.value !== '1'
        ) ||
        this.props.multiple
      ) {
        value = event.target.value
      }
      this.props.onChange(value)
    } else {
      this.props.onChange(event.target.value)
    }
  }

  _onChangeImage = event => {
    const input = event.target
    if (input.files && input.files[0]) {
      const file = input.files[0]
      this._getBase64(file).then(image => {
        const base64 = image.result
        this.setState({
          filePreview: base64
        })
        this.props.onChange(base64)
        this.props.onSelectFile(file)
        this.setState({ previewImageName: file.name })
      })
    }
  }

  _onChangeFile = event => {
    const input = event.target
    if (input.files && input.files[0]) {
      const file = input.files[0]
      this._getBase64(file).then(image => {
        const base64 = image.result
        this.setState({
          filePreview: base64
        })
        this.props.onChange(base64)
        this.props.onSelectFile(file)
        this.setState({ previewFileName: file.name })
      })
    }
  }

  _getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', e => {
        resolve({
          result: e.target.result,
          error: e.target.error
        })
      })
      reader.readAsDataURL(file)
    })
  }

  _getLabel = () => {
    const { type, label, multiple, icon } = this.props
    let iconTag = null
    if (icon) {
      iconTag = <Icon size="small" name={icon} />
    }

    switch (type) {
      case 'checkbox':
        if (multiple !== true) {
          return false;
        }
        return (
          <label
            className="form__label form__label--small"
          >{label}</label>
        )
      case 'switch':
        return false

      default:
        return (
          <label
            className="form__label form__label--small"
          >{iconTag} {label}</label>
        )
    }
  }

  _getInput = () => {
    const { props } = this
    const {
      type,
      label,
      preview,
      multiple,
      previewOriginal
    } = props
    const safeProps = this._safeProps(props)
    const {
      showCalendar,
      previewImageName,
      previewFileName
    } = this.state
    switch (type) {
      case 'checkbox':
        if (!multiple) {
          return (
            <div>
              <label
                className="form__label form__label--checkbox"
              >
                <Checkbox {...safeProps} onChange={this._onChange} />
                <span
                  className="form__label__text"
                >{label}</span>
              </label>
            </div>
          )
        }
        return (
          <div className="form__checkbox-multiple">
            {this._getCheckboxOptions()}
          </div>
        )

      case 'radio':
        return (
          <div className="form__radio-group">
            {this._getRadioOptions()}
          </div>
        )

      case 'radioButtons':
        return (
          <div className="form__radio-buttons form__radio-buttons--full">
            {this._getRadioButtons()}
          </div>
        )

      case 'textarea':
        return (
          <Textarea {...safeProps} onChange={this._onChange} />
        )

      case 'select':
        return (
          <Select {...safeProps} onChange={this._onChange} />
        )

      case 'calendar':
        let dateValue = null
        let formatedDate = ''
        if (props.value) {
          try {
            dateValue = props.value
            formatedDate = fecha.format(dateValue, 'DD/MM/YYYY')
          } catch (error) {
            dateValue = null
            formatedDate = ''
          }
        }
        return (
          <div
            className='input-and-calendar'
            onClick={() => {
              if (!showCalendar) {
                this.setState({
                  showCalendar: true
                })
              }
            }}
          >
            <Input
              {...safeProps}
              value={formatedDate}
              readOnly
            />
            {showCalendar &&
              <div className="calendar">
                <Calendar
                  value={dateValue}
                  prevLabel={<Icon name='arrow-prev' />}
                  prev2Label={<Icon name='arrow-double-prev' />}
                  nextLabel={<Icon name='arrow-next' />}
                  next2Label={<Icon name='arrow-double-next' />}
                  onChange={value => {
                    props.onChange(value)
                    this.setState({
                      showCalendar: false
                    })
                  }}
                />
              </div>
            }
          </div>
        )

      case 'switch':
        return (
          <div>
            <label
              className="form__label form__label--checkbox"
            >
              <Switch {...safeProps} onChange={this._onChange} />
              <span
                className="form__label__text"
              >{label}</span>
            </label>
          </div>
        )

      case 'file': {
        let inputClasses = [
          'input-and-preview',
          'input-and-preview--file'
        ]
        if (props.value || preview) {
          inputClasses.push('input-and-preview--selected')
        }
        return (
          <div className={inputClasses.join(' ')}>
            <label>
              <Input
                {...safeProps}
                type='file'
                value=''
                onChange={this._onChangeFile}
              />
              <span className="input-and-preview__label">
                {previewFileName}
              </span>
            </label>
            {preview &&
              <div className="input-and-preview__current-file">
                <p>{label} atual:</p>
                <a href={preview} target="_blank" rel="noopener noreferrer">{preview}</a>
              </div>
            }
          </div>
        )
      }

      case 'image': {
        let inputClasses = [
          'input-and-preview',
          'input-and-preview--image',
        ]
        if (props.value || preview) {
          inputClasses.push('input-and-preview--selected')
        }
        return (
          <div className={inputClasses.join(' ')}>
            <label>
              <Input
                {...safeProps}
                type='file'
                value=''
                onChange={this._onChangeImage}
              />
              <span className="input-and-preview__label">
                {this._getFilePreview()}
                {previewImageName}
              </span>
            </label>
            {preview &&
              <div style={{ margin: '10px 0 0 0' }}>
                <p style={{ margin: '0 0 10px 0' }}>{label} atual:</p>
                <p><a href={preview} target="_blank" rel="noopener noreferrer">{preview}</a></p>
                {previewOriginal &&
                  <React.Fragment>
                    <p style={{ margin: '10px 0' }}>{label} original:</p>
                    <p><a href={previewOriginal} target="_blank" rel="noopener noreferrer">{preview}</a></p>
                  </React.Fragment>
                }
              </div>
            }
          </div>
        )
      }

      case 'dropzone': {
        return (
          <Dropzone
            onDrop={async acceptedFiles => {
              this.props.onSelectFile(
                await Promise.all(
                  acceptedFiles.map(async file => {
                    return {
                      file,
                      base64: (await this._getBase64(file)).result
                    }
                  })
                )
              )
            }}
          >
            {({getRootProps, getInputProps}) => (
              <div className="dropzone">
                <div className="dropzone__wrapper" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <h3 className="dropzone__title">Solte os arquivos aqui</h3>
                  <p className="dropzone__text">Ou clique para selecionar</p>
                </div>
              </div>
            )}
          </Dropzone>
        )
      }

      case 'texteditor':
        return (
          <RichTextEditor
            className="form__text-editor"
            toolbarClassName="form__text-editor__toolbar"
            value={this.state.textEditorValue}
            onChange={value => {
              this.setState({
                textEditorValue: value
              }, () => {
                this.props.onChange(value.toString('html'))
              })
            }}
          />
        )

      case 'slug':
        return (
          <Slug
            {...safeProps}
            mirror={this.props.mirror}
            onChange={this._onChange}
          />
        )

      case 'tag': {
        const options = this.props.options.map((item) => {
          return item
        })
        return (
          <div className="form__radio-group">
            <CreatableSelect
              isMulti
              value={safeProps.value}
              options={options}
              onChange={(items) => {
                this._onChange({
                  target: {
                    type: 'text',
                    value: items
                  },
                })
              }}
              placeholder="Selecione"
              formatCreateLabel={(inputValue) => `Criar "${inputValue}"`}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary75: '#888',
                  primary50: '#aaa',
                  primary25: '#c5c5c5',
                  primary: '#676767',
                },
              })}
              styles={{
                multiValue: base => ({
                  ...base,
                  backgroundColor: 'white',
                  border: 0,
                }),
                control: base => ({
                  ...base,
                  backgroundColor: '#c5c5c5',
                  border: 0,
                  boxShadow: 'none',
                }),
                clearIndicator: base => ({
                  ...base,
                  color: '#676767',
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#676767',
                }),
                indicatorSeparator: base => ({
                  ...base,
                  backgroundColor: '#aaa',
                }),
              }}
            />
          </div>
        )
      }

      default:
        return (
          <Input {...safeProps} onChange={this._onChange} />
        )
    }
  }

  _getFilePreview = () => {
    const { preview } = this.props
    const { filePreview } = this.state
    let src = null
    if (filePreview) {
      src = filePreview
    } else if (preview) {
      src = preview
    }
    if (src) {
      return (
        <img
          className='form__control__preview'
          src={src}
          alt='Preview'
        />
      )
    }
  }

  _getRadioOptions = () => {
    const { props } = this
    const { options } = props
    const safeProps = this._safeProps(props)
    return options.map((option, index) => {
      const checked = option.value.toString() === props.value.toString()
      return (
        <div key={index}>
          <label
            className="form__label form__label--radio"
          >
            <Radio {...safeProps} checked={checked} value={option.value} onChange={this._onChange} />
            <span
              className="form__label__text"
            >{option.label}</span>
          </label>
        </div>
      )
    })
  }

  _getRadioButtons = () => {
    const { props } = this
    const { options } = props
    const safeProps = this._safeProps(props)
    return options.map((option, index) => {
      const checked = option.value.toString() === props.value.toString()
      const radioButtonClasses = classNames({
        'radio-button': true,
        'radio-button--checked': checked,
        'radio-button--icon': !!option.icon,
      })
      return (
        <div key={index} className={radioButtonClasses}>
          <label
            className="button form__label form__label--radio"
          >
            <Radio
              {...safeProps}
              checked={checked}
              value={option.value}
              onChange={this._onChange}
            />
            {option.icon &&
              <Icon name={option.icon} />
            }
            <span
              className="form__label__text"
            >{option.label}</span>
          </label>
        </div>
      )
    })
  }

  _getCheckboxOptions = () => {
    const { props } = this
    const { options } = props
    const safeProps = this._safeProps(props)
    return options.map((option, index) => {
      const checked = (
        props.value.includes(option.value) ||
        props.value.includes(+option.value)
      )
      return (
        <label
          key={index}
          className="form__label form__label--checkbox"
        >
          <Checkbox {...safeProps} checked={checked} value={option.value} onChange={this._onChange} />
          <span
            className="form__label__text"
          >{option.label}</span>
        </label>
      )
    })
  }

  _safeProps = props => {
    const newProps = { ...props }
    delete newProps.onSelectFile
    delete newProps.previewOriginal
    delete newProps.mirror
    delete newProps.helpText
    delete newProps.children
    return newProps
  }

  _getHelpText = () => {
    const { helpText } = this.props
    if (helpText) {
      return <p className="form__control__help-text">{helpText}</p>
    }
  }
}

export default FormControl
