const initialState = {
  data: null,
  list: [],
  paging: null,
  sending: false,
  filters: {}
}

const identifier = 'content'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_CONTENTS':
      nextState = {
        ...state,
        list: action.data.data,
        paging: action.data.paging
      }
      break

    case 'SET_CONTENT':
      nextState.data = action.data
      break

    case 'SET_CONTENT_FILTERS':
      nextState.filters = action.data
      break

    case 'UPDATE_CONTENT_STATUS':
      nextState.list = nextState.list.map(item => {
        if (+item.id === +action.data.id) {
          item.status = action.data.status
        }
        return item
      })
      break

    case 'UPDATE_CONTENT_HIGHLIGHTED':
      const highlighted = action.data.highlighted
      nextState.list = nextState.list.map(item => {
        item.highlighted = +item.id === +action.data.id
          ? highlighted
          : false
        return item
      })
      break

    default:
      nextState = state
      break
  }

  return nextState
}
