import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'tag'

const _setTags = data => ({ type: 'SET_TAGS', data })
const _setTag = data => ({ type: 'SET_TAG', data })
const _updateTag = data => ({ type: 'UPDATE_TAG', data })
const _updateStatus = data => ({ type: 'UPDATE_TAG_STATUS', data })
const _setTagFilters = data => ({ type: 'SET_TAG_FILTERS', data })

export const loadTags = (token, filters = {}) => {
  return async dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setTagFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/tags', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setTags(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadTag = (token, id) => {
  return async dispatch => {
    dispatch(_setTag(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/tags/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setTag(result.data))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createTag = (token, data, redirectAfter = true) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/tags', data, token)
      .then(result => {
        if (result.success) {
          if (redirectAfter) {
            dispatch(notificate(result.message, 'info'))
            dispatch(setRedirect({ screen: 'tags' }))
          }
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateTag = (token, data) => {
  return async dispatch => {
    updateTagGeneric(dispatch, token, data.id, data, identifier, result => {
      dispatch(_updateTag(result.data))
      dispatch(notificate(result.message, 'info'))
      dispatch(setRedirect({ screen: 'tags' }))
    })
  }
}

export const toggleStatus = (token, id, status) => {
  return async dispatch => {
    const data = { status }
    updateTagGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateStatus(result.data))
    })
  }
}

const updateTagGeneric = (dispatch, token, id, data, identifier, onSuccess) => {
  dispatch(beforeSend(identifier))
  return api.put(`admin/tags/${id}`, data, token)
    .then(result => {
      if (result.success) {
        onSuccess(result)
      } else {
        dispatch(notificate(result.message, 'error'))
      }
      dispatch(afterResponse(identifier))
    })
    .catch(error => {
      dispatch(notificate(error.message, 'error'))
      dispatch(afterResponse(identifier))
    })
}

export const destroyTag = (token, id) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/tags/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
