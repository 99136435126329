import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Link } from '../atoms'
import { Spinner } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadCategories,
  destroyCategory,
  captureCategory
} from '../../store/actions/category'
import { links, navigate } from '../../utils'

class Categories extends Component {
  state = {
    filters: {
      page: 1,
      limit: 50,
      search: '',
      filled: true
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.category
    const { currentId } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link
            button
            disabled={sending}
            size='small'
            to={links.get('createCategory')}
          >
            + Criar Categoria
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.category.filters}
          filters={['search']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
          customActions={this._getCustomActions()}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadCategories(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'Nome',
      field: 'name',
      filter: (value, data) => {
        let name = value
        if (data.parent) {
          name = `${data.parent.name} > ${name}`
        }
        return name
      }
    }, {
      name: 'Slug',
      field: 'slug'
    }, {
      name: 'Ativo',
      field: 'statusFormated'
    }]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editCategory',
      params: { id: item.id },
      props: this.props
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.admin.admin
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyCategory(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    return list.map(item => {
      return {
        ...item,
        statusFormated: (
          item.status === 'enabled'
            ? 'Sim'
            : 'Não'
        )
      }
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.category.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.category.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _sortColumn = column => {
    return direction => {
      const signal = direction === 'desc' ? '-' : ''
      const sort = `${signal}${column}`
      const filters = {
        ...this.state.filters,
        sort
      }
      this.setState({ filters }, this._reloadData)
    }
  }

  _currentSortColumn = column => {
    const { sort } = this.state.filters
    let direction = 'asc'
    if (sort.includes(column)) {
      direction = sort[0] === '-' ? 'desc' : 'asc'
    }
    return direction
  }

  _getCustomActions = () => {
    return [
      {
        component: <Icon title="Capturar videos" name="youtube" />,
        isValidRow: (row) => {
          return row.playlist_id
        },
        handler: (row) => {
          const { token } = this.props.admin.admin
          this.props.captureCategory(token, row.id)
        }
      }
    ]
  }
}

const mapStateToProps = ({ admin, category }) => ({
  admin,
  category,
})

const mapDispatchToProps = {
  loadCategories,
  destroyCategory,
  captureCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
