const initialState = {
  data: null,
  list: [],
  paging: {},
  filters: {},
  sending: false
}

const identifier = 'contact'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_CONTACTS':
      nextState.list = action.data.data
      nextState.paging = action.data.paging
      break

    case 'SET_CONTACT':
      nextState.data = action.data
      break

    case 'SET_CONTACT_FILTERS':
      nextState.filters = action.data
      break

    default:
      nextState = state
      break
  }

  return nextState
}
