import React, { Component } from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { Button, Icon } from '../atoms'
import { Spinner } from '../molecules'
import { Confirmation, SettingItem } from '../organisms'
import {
  loadApiServices,
  destroyApiService,
  loadAuthURL,
  authCallback
} from '../../store/actions/api_service'
import {
  loadSettings,
  updateSetting
} from '../../store/actions/setting'
import { notificate } from '../../store/actions/notification'
import { navigate } from '../../utils'

class ApiServices extends Component {
  state = {
    currentItem: null
  }

  componentDidMount() {
    this._loadData()
  }

  render() {
    const { sending } = this.props.apiService
    const { currentItem } = this.state
    const items = this._getItems()
    return (
      <div>
        <Confirmation
          opened={currentItem !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        {!sending &&
          <div className="api-services">
            {items}
          </div>
        }
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.admin.admin
    const { service } = this.props.match.params
    const { search } = this.props.location
    if (service) {
      const { code } = qs.parse(search.substring(1))
      if (code) {
        this.props.authCallback(token, service, code)
          .then(result => {
            if (!result.success) {
              this.props.notificate(
                result.message || 'Não foi possível autenticar',
                'error'
              )
            }
            navigate.to({
              screen: 'apiServices',
              props: this.props
            })
          })
          .catch(() => {
            navigate.to({
              screen: 'apiServices',
              props: this.props
            })
          })
      } else {
        navigate.to({
          screen: 'apiServices',
          props: this.props
        })
      }
    } else {
      this.props.loadApiServices(token)
      this.props.loadSettings(token)
    }
  }

  _onDelete = item => {
    this.setState({ currentItem: item })
  }

  _onCancel = () => {
    this.setState({ currentItem: null })
  }

  _onConfirm = () => {
    const { currentItem } = this.state
    this.setState({ currentItem: null }, () => {
      this._disconnect(currentItem)
    })
  }

  _reloadData = () => {
    this._loadData()
  }

  _formatList = list => {
    const translateStatus = {
      enabled: 'Conectado',
      expired: 'Expirado',
      failed: 'Falha ao autenticar'
    }
    return list.map(item => {
      return {
        ...item,
        statusFormatted: translateStatus[item.status]
      }
    })
  }

  _fillServices = list => {
    const services = ['youtube']
    const listServices = list.map(item => item.name)
    const newList = [...list]
    for (const service of services) {
      if (!listServices.includes(service)) {
        newList.push({
          id: null,
          name: service
        })
      }
    }
    newList.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return newList
  }

  _getItems = () => {
    const { list } = this.props.apiService
    let formattedList = this._formatList(list)
    formattedList = this._fillServices(formattedList)
    return formattedList.map((item, index) => {
      const actionClasses = ['api-service__actions']
      if (item.user_name && item.status !== 'enabled') {
        actionClasses.push('api-service__actions--connected')
      }
      return (
        <div key={index} className="api-service">
          <h2 className="api-service__title">
            <Icon name={item.name} />
            <span>{item.name}</span>
          </h2>
          <div className="api-service__info">
            {!item.user_name &&
              <p className="api-service__info__value">Você precisa se conectar, clique no botão abaixo.</p>
            }
            {item.user_name &&
              <React.Fragment>
                <p className="api-service__info__value">Logado como: <strong>{item.user_name}</strong></p>
                <p className="api-service__info__value">
                  Status: <strong>{item.statusFormatted}</strong>
                  {item.status !== 'enabled' && item.status_notes &&
                    <em> ({item.status_notes})</em>
                  }
                </p>
                {this._getSettings(item.name)}
              </React.Fragment>
            }
          </div>
          <div className={actionClasses.join(' ')}>
            {!item.user_name &&
              <Button
                size="small"
                className="api-service__actions__button"
                intent="success"
                onClick={() => this._connect(item)}
              >Conectar</Button>
            }
            {item.user_name &&
              <React.Fragment>
                {item.status !== 'enabled' &&
                  <Button
                    size="small"
                    className="api-service__actions__button"
                    onClick={() => this._connect(item)}
                  >Reconectar</Button>
                }
                <Button
                  size="small"
                  intent="danger"
                  className="api-service__actions__button"
                  onClick={() => this._onDelete(item)}
                >Desconectar</Button>
              </React.Fragment>
            }
          </div>
        </div>
      )
    })
  }

  _connect = item => {
    const { token } = this.props.admin.admin
    this.props.loadAuthURL(token, item.name).then(result => {
      if (result && result.success) {
        window.location.href = result.data.url
      }
    })
  }

  _disconnect = item => {
    const { token } = this.props.admin.admin
    this.props.destroyApiService(token, item.id)
  }

  _getSettings = service => {
    const { sending, list, currentID } = this.props.setting
    const items = [
      {
        service: 'youtube',
        name: 'youtube_capture_enabled',
        type: 'switch'
      }
    ]
    return items
      .filter(item => item.service === service)
      .map(item => {
        const setting = list.find(v => v.name === item.name)
        return {
          ...item,
          setting
        }
      })
      .filter(item => item.setting)
      .map((item, index) => {
        const sendingItem = (
          sending &&
          item.setting.id === currentID
        )
        return (
          <SettingItem
            key={index}
            id={item.setting.id}
            description={item.setting.description}
            name={item.name}
            value={item.setting.value}
            public={item.setting.public}
            onChange={this._updateSetting}
            sending={sendingItem}
            type={item.type}
          />
        )
      })
  }

  _updateSetting = data => {
    const { token } = this.props.admin.admin
    this.props.updateSetting(token, data)
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    setting: state.setting,
    apiService: state.apiService
  }
}

const mapDispatchToProps = {
  loadApiServices,
  destroyApiService,
  loadAuthURL,
  authCallback,
  notificate,
  loadSettings,
  updateSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiServices)
