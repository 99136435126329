import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'content'

const _setContents = data => ({ type: 'SET_CONTENTS', data })
const _setContent = data => ({ type: 'SET_CONTENT', data })
const _updateContent = data => ({ type: 'UPDATE_CONTENT', data })
const _setContentFilters = data => ({ type: 'SET_CONTENT_FILTERS', data })
const _updateStatus = data => ({ type: 'UPDATE_CONTENT_STATUS', data })
const _updateHighlighted = data => ({ type: 'UPDATE_CONTENT_HIGHLIGHTED', data })

export const loadContents = (token, filters = {}) => {
  return async dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setContentFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/contents', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setContents(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadContent = (token, id) => {
  return async dispatch => {
    dispatch(_setContent(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/contents/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setContent(result.data))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createContent = (token, data) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/contents', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'contents' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateContent = (token, data) => {
  return async dispatch => {
    updateContentGeneric(dispatch, token, data.id, data, identifier, result => {
      dispatch(_updateContent(result.data))
      dispatch(notificate(result.message, 'info'))
      dispatch(setRedirect({ screen: 'contents' }))
    })
  }
}

export const toggleStatus = (token, id, status) => {
  return async dispatch => {
    const data = { status }
    updateContentGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateStatus(result.data))
    })
  }
}

export const toggleHighlighted = (token, id, highlighted) => {
  return async dispatch => {
    const data = { highlighted }
    updateContentGeneric(dispatch, token, id, data, null, result => {
      dispatch(_updateHighlighted(result.data))
    })
  }
}

const updateContentGeneric = (dispatch, token, id, data, identifier, onSuccess) => {
  dispatch(beforeSend(identifier))
  return api.put(`admin/contents/${id}`, data, token)
    .then(result => {
      if (result.success) {
        onSuccess(result)
      } else {
        dispatch(notificate(result.message, 'error'))
      }
      dispatch(afterResponse(identifier))
    })
    .catch(error => {
      dispatch(notificate(error.message, 'error'))
      dispatch(afterResponse(identifier))
    })
}

export const destroyContent = (token, id) => {
  return async dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/contents/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
