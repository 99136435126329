import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'tags'

export default {
  create(data) {
    return v8n()
      .schema({
        name: v8n().not.null().string().minLength(2).maxLength(255),
        status: v8n().validStatus(),
      })
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },

  edit(data) {
    return v8n()
      .schema({
        name: v8n().not.null().string().minLength(2).maxLength(255),
        status: v8n().validStatus(),
      })
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  }
}
