import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createTag } from '../../store/actions/tag'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { TagForm } from '../organisms'

class CreateTag extends Component {
  render() {
    const { sending } = this.props.tag
    return (
      <div>
        <BackToList to='tags' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de Tag</h1>
            <TagForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='tag' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.admin.admin
    this.props.createTag(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = ({ admin, tag }) => ({
  admin,
  tag
})

const mapDispatchToProps = {
  createTag,
  notificate
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTag)
